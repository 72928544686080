import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { ControleFeriasService } from './controleferias.service';
import { conversaoFormatoData } from '../../../../utils/utils';

export class ControleFeriasController {

    static $inject = [
        '$rootScope',
        '$state',
        '$stateParams',
        'NewToaster',
        'ControleFeriasService',
        'ListagemService',
        'RelatoriosService',
    ];

    // initial state
    public entities: any = [];
    public selected_trabalhador: any = null;
    public orderbyparams: any = {};
    public lookupTrabalhadores = false;
    public primeiroLookupTrabalhadoresCarregado = false;
    public tipo: any;
    public tipoInicial: any;
    public status: any;
    public selectAll: any;
    public carregandoRelatorio: Boolean;
    public selected_lotacao: any = null;
    public listagemPermissao: Array<string> = ['visualizar_ferias'];
    public permissaoGrupoEmpresarial = false;
    public empresa = '';
    public estabelecimento = '';
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;

    constructor (
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: string[], grupo: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: { pop: Function },
        public ControleFerias: ControleFeriasService,
        public ListagemService: ListagemService,
        public RelatoriosService: any,
    ) {
        if (!$rootScope.temPermissao('visualizar_ferias')) {
            $state.go('dp', $stateParams);
        }

        this.permissaoGrupoEmpresarial = $rootScope.temPermissaoGrupoEmpresarial(
            this.listagemPermissao,
            $rootScope.nsjGlobals.a.grupoempresarial
        );

        this.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;

        this.montaListagemVisibilidade();

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });

        if ($stateParams.tipo) {
            this.tipo = $stateParams.tipo;
            this.tipoInicial = $stateParams.tipo;
        } else {
            this.tipo = '-1';
            this.tipoInicial = '-1';
        }
        this.orderbyparams.current = 'nome';
        ControleFerias.constructors.order = {
            'nome': 'ASC'
        };
        this.status = '00';
        ControleFerias.constructors = [];
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.empresa ? this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao) : [];
        this.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.selected_trabalhador = this.selected_lotacao = null;
        this.filter();
    }

    carregandoLookups() {
        return this.lookupTrabalhadores;
    }

    forcaGestor() {
        return this.$rootScope.modoGestorPermissao('criar_ferias');
    }

    isBusy() {
        return this.ControleFerias.loadParams.busy;
    }

    async filter() {
        let params = {
            empresa: this.empresa,
            estabelecimento: this.estabelecimento
        };
        let constructor: any = { ...params };
        if (this.orderbyparams.current) {
            constructor.order = { [this.orderbyparams.current]: 'ASC' };
        }
        constructor.status = this.status;
        if (this.selected_trabalhador) {
            constructor.trabalhador = this.selected_trabalhador.trabalhador;
        }
        if (this.selected_lotacao) {
            constructor.lotacao = this.selected_lotacao.lotacao;
        }
        constructor.tipo = this.tipo;
        this.ControleFerias.constructors = constructor;
        this.entities = await this.ControleFerias.loadEntitiesRelatorioControleFerias('controleferias_relatorio');
        this.tratarEntities();
        this.$rootScope.$broadcast('trabalhador_refresh', {
            ...params,
            status: this.status,
            tipo_excluir: 1,
            tipo: this.tipo,
            forcagestor: this.forcaGestor(),
        });
        this.$rootScope.$broadcast('lotacao_refresh', params);
        this.selectAll = false;
    }

    order(k: any) {
        this.orderbyparams.current = k;
        this.filter();
    }

    montaArrayTrabalhadores() {
        let trabalhadores: any = [];
        if (this.nenhumTrabalhadorSelecionado() || this.selectAll) {
            return trabalhadores;
        } else {
            for (let i = 0; i < this.entities.length; i++) {
                if (this.entities[i].selected) {
                    trabalhadores.push(this.entities[i].trabalhador);
                }
            }
        }
        return trabalhadores;
    }

    nenhumTrabalhadorSelecionado() {
        for (let i = 0; i < this.entities.length; i++) {
            if (this.entities[i].selected) {
                return false;
            }
        }
        return true;
    }

    changeCheck() {
        let todosSelecionados = true;
        for (let i = 0; i < this.entities.length; i++) {
            if (!this.entities[i].selected) {
                todosSelecionados = false;
            }
        }
        this.selectAll = todosSelecionados;
    }

    toggleSelectAll() {
        for (let i = 0; i < this.entities.length; i++) {
            this.entities[i].selected = this.selectAll;
        }
    }

    openRelatorio() {
        if (this.entities.length === 0 && !this.isBusy()) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não existem dados para gerar o relatório'
            });
            return;
        }
        if (this.nenhumTrabalhadorSelecionado()) {
            this.NewToaster.pop({
                type: 'error',
                title: 'É preciso selecionar pelo menos um trabalhador'
            });
            return;
        }

        let constructor = angular.copy(this.ControleFerias.constructors);
        if (!constructor.trabalhador) {
            let trabalhadores = this.montaArrayTrabalhadores();
            if (trabalhadores.length > 0) {
                constructor.trabalhador = trabalhadores;
            }
        }
        constructor.pdf = true;
        this.carregandoRelatorio = true;
        let callback = (): void => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('controleferias_relatorio', constructor, callback);
    }

    baixarPlanilhaExcel() {
        if (this.entities.length === 0 && !this.isBusy()) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não existem dados para gerar o relatório'
            });
            return;
        }
        if (this.nenhumTrabalhadorSelecionado()) {
            this.NewToaster.pop({
                type: 'error',
                title: 'É preciso selecionar pelo menos um trabalhador'
            });
            return;
        }

        let constructor = angular.copy(this.ControleFerias.constructors);
        if (!constructor.trabalhador) {
            let trabalhadores = this.montaArrayTrabalhadores();
            if (trabalhadores.length > 0) {
                constructor.trabalhador = trabalhadores;
            }
        }
        constructor.xlsx = true;
        this.carregandoRelatorio = true;
        let callback = (): void => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.baixarPlanilhaXlsx('controleferias_relatorio', constructor, callback);
    }

    exibePeriodoFerias(dataInicial: string, dataFinal: string) {
        return conversaoFormatoData(dataInicial) + ' até ' + conversaoFormatoData(dataFinal);
    }

    abonoDeFerias(trabalhador: any) {
        let abonoQuantidade = 0;

        if (trabalhador.ferias) {
            trabalhador.ferias.forEach((feriasTrabalhador: any) => {
                if (feriasTrabalhador.diasvendidos) {
                    abonoQuantidade += feriasTrabalhador.diasvendidos;
                }
            });
            return abonoQuantidade.toString();
        }

        return '-';
    }

    optouDecimoTerceiro(trabalhador: any): string {
        if (trabalhador.ferias) {
            let optou: boolean = false;

            trabalhador.ferias.forEach((feriasTrabalhador: any) => {
                if (feriasTrabalhador.adto13nasferias) {
                    optou = true;
                    return;
                }
            });

            return optou ? 'Sim' : 'Não';
        }

        return '-';
    }

    private tratarEntities(): void {
		this.entities.forEach((entity: any) => {
			entity.dataadmissao = conversaoFormatoData(entity.dataadmissao);
			entity.inicioperiodoaquisitivoferias = conversaoFormatoData(entity.inicioperiodoaquisitivoferias);
			entity.vencimento = conversaoFormatoData(entity.vencimento);
			entity.datalimitegozo = conversaoFormatoData(entity.datalimitegozo);
		});
	}
}

angular.module('main').controller('ControleFeriasController', ControleFeriasController);
